<template>
  <!-- 添加/编辑问题页面 -->
  <div class="positionT0L0">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 5 }"
    >
      <a-form-model-item label="问题编号" prop="faqNo">
        <a-input v-model="form.faqNo" placeholder="请输入编号"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="问题标题" prop="title">
        <a-input
          v-model="form.title"
          :maxLength="32"
          placeholder="请输入问题标题"
        >
          <div slot="addonAfter">{{ form.title.length }}/32</div>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="问题内容" prop="content">
        <a-textarea placeholder="请输入内容" v-model="form.content" :rows="5" />
        <span class="form_span">{{ form.content.length }}/300</span>
      </a-form-model-item>
    </a-form-model>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)"
        >返回</a-button
      >
      <a-button
        type="primary"
        @click="keepClick"
        :loading="keepLoading"
        :disabled="keepLoading"
        >保存</a-button
      >
    </FooterToolBar>
  </div>
</template>

<script>
import { FaqInfoApi, FaqAddApi, FaqUpdateApi } from "@/request/api/setUp";
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    this.form.id = this.$route.params.id;
    if (this.form.id != "0") {
      FaqInfoApi({ id: this.form.id }).then(({ code, data }) => {
        if (code == 200) {
          this.form = data;
        }
      });
    }
  },
  data() {
    return {
      keepLoading: false, // 保存的loading图
      form: {
        id: "",
        faqNo: "",
        title: "",
        content: "",
      },
      rules: {
        faqNo: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
          {
            max: 32,
            message: "长度最长为32个字",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
          {
            max: 300,
            message: "长度最长为300个字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 保存的按钮
    keepClick() {
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.id == 0) {
            FaqAddApi(this.form).then(({ code }) => {
              if (code == 200) {
                message.success("添加成功");
                this.$router.push("/setUp/commonProblem");
              }
            });
          } else {
            console.log(22);
            FaqUpdateApi(this.form).then(({ code }) => {
              if (code == 200) {
                message.success("编辑成功");
                this.$router.push("/setUp/commonProblem");
              }
            });
          }
        } else {
          message.error("还有必填项未完成");
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
/deep/.ant-input {
  resize: none;
}
</style>