<template>
  <!-- 常见问题页面 -->
  <div class="padding24 color_white" id="common_Problem">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left">
          <a-button
            icon="plus"
            type="primary"
            @click="addOrEditClick(0)"
            v-show="isShowAdd"
          >
            新建问题
          </a-button>
        </div>
        <div class="top_rigiht">
          <a-input
            v-model="searchVal"
            placeholder="请输入编号 / 问题标题"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            icon="search"
            type="primary"
            @click="searchClick"
            :disabled="isDisableSearch"
            >搜索</a-button
          >
        </div>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="operation" slot-scope="operation, record">
          <a
            v-show="isShowEdit"
            class="margin_right20"
            @click="addOrEditClick(record.id)"
          >
            编辑
          </a>
          <a v-show="isShowDel" @click="delClick(record.id)">删除</a>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { ProblemListApi, FaqDelApi } from "@/request/api/setUp";
import MyPagination from "@/components/pagination/MyPagination";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    if (codeFn("/admin/home/faq/add")) this.isShowAdd = true;
    if (codeFn("/admin/home/faq/update")) this.isShowEdit = true;
    if (codeFn("/admin/home/faq/delete/{id}")) this.isShowDel = true;
  },
  data() {
    return {
      searchVal: "",
      pageNo: 1,
      pageSize: 20,
      count: 0,
      tableLoading: true, // 表格的loading图
      isDisableSearch: false, // 搜索按钮是否禁用
      columns: [
        {
          title: "问题编号",
          dataIndex: "faqNo",
          key: "faqNo",
        },
        {
          title: "问题标题",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          width: "12%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isShowAdd: false, // 新增是否隐藏
      isShowEdit: false, // 编辑是否隐藏
      isShowDel: false, // 编辑是否隐藏
    };
  },
  watch: {
    searchVal(newVal) {
      if (newVal.length <= 0) {
        this.ProblemListFn();
      }
    },
    $route: {
      handler(to) {
        if (to.path == "/setUp/commonProblem") {
          this.ProblemListFn();
        }
      },
      immediate: true,
    },
  },
  methods: {
    //   新建/编辑的按钮
    addOrEditClick(id) {
      this.$router.push(`/setUp/commonProblem/addProblem/${id}`);
    },
    // 回车和搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.ProblemListFn();
    },
    // 删除的按钮
    delClick(id) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复, 是否确认删除",
        okType: "danger",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          FaqDelApi({ id }).then(({ code, data }) => {
            if (code == 200) {
              message.success("删除成功", 2);
              let tableData = that.tableData;
              that.tableData = tableData.filter((item) => item.id !== id);
            }
          });
        },
      });
    },

    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.ProblemListFn();
    },
    //  问题列表函数
    ProblemListFn() {
      ProblemListApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        keyword: this.searchVal,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          this.count = data.count;
          data.data.forEach((item) => {
            item.key = item.id;
          });
          this.tableData = data.data;
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#common_Problem");
    },
  },
};
</script>
 
<style lang="less" scoped>
#common_Problem {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
    }
  }
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>