// 设置相关接口
import request from '@/request/request'


// 问题列表搜索查询
export const ProblemListApi = (params) => request.post('/admin/home/faq', params);
// 查询问题详情
export const FaqInfoApi = (params) => request.get(`/admin/home/faq/info/${params.id}`, { params });
// 添加常见问题
export const FaqAddApi = (params) => request.post('/admin/home/faq/add', params);
// 编辑常见问题
export const FaqUpdateApi = (params) => request.post('/admin/home/faq/update', params);
// 删除常见问题
export const FaqDelApi = (params) => request.post(`/admin/home/faq/delete/${params.id}`, params);
